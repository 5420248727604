.item {
    width: 200px;
    height: 200px;
    margin: 50px auto;
    padding-top: 75px;
    background: #ccc;
    text-align: center;
    color: #FFF;
    font-size: 3em;
  }

//   .Box {
//     overflow-y: scroll; /* cho phép cuộn trang */
//     scroll-snap-type: y mandatory; /* kích hoạt scroll snap */
//   }
  
//   /* thiết lập scroll snap cho mỗi phần tử */
//   #page1, #page2 {
//     height: 100vh;
//     scroll-snap-align: start;
//   }
  